.student-cover-image {
  height: 250px;
  margin-left: -40px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(39, 39, 39, 0.7)), to(rgba(39, 39, 39, 0.7))), url(https://uploads-ssl.webflow.com/5f11b0f9a179803d92d0d70e/5f4949b94ed6d65fbe4b23d1_perry-grone-lbLgFFlADrY-unsplash.jpg);
  background-image: linear-gradient(180deg, rgba(39, 39, 39, 0.7), rgba(39, 39, 39, 0.7)), url(https://uploads-ssl.webflow.com/5f11b0f9a179803d92d0d70e/5f4949b94ed6d65fbe4b23d1_perry-grone-lbLgFFlADrY-unsplash.jpg);
  background-position: 0px 0px, 50% 57%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, scroll;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 110px));
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 110px));
    
}