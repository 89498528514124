.basic-info-container {
  display: flex;
  flex-wrap: wrap; }

.basic-info-card {
  width: 33.33%;
  padding: 15px;
  @media screen and (max-width: 1242px) and (min-width: 768px), (max-width: 684px) {
    width: 50%; } }

.basic-info-card-heading {
  font-weight: 500;
  margin-bottom: 5px; }

.basic-info-card-value {
  color: #8f8f8f;
  overflow-wrap: break-word; }
