@import "../../_variables";

.main-content {
  flex-grow: 1;
  overflow: scroll;
  background-color: white; }

.main-content-inner-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%; }

.main-content-header {
  padding: 0.75rem 1.5rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: $white;
  border-bottom: 1px $gray-200 solid;
  position: sticky;
  top: 0;
  z-index: 50;
  @media screen and (max-width: 379px) {
    flex-wrap: wrap; }

  .button-wrapper {
    @media screen and (max-width: 379px) {
      margin-top: 10px; } }

  .content-header-text {
    display: flex;
    align-items: center;
    width: 68%;
    @media screen and (max-width: 379px) {
      width: 100%; } } }

.main-content-footer {
  margin-top: auto;
  padding: 0.75rem 1.5rem;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  border-top: 1px #e5e7eb solid;

  &.mcf-left {
    justify-content: flex-start; }
  &.mcf-center {
    justify-content: center; }
  &x.mcf-right {
    justify-content: flex-end; } }
