@import "./_variables.sass";

font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Bold.eot");
  src: local("Euclid Circular A Bold"), local("EuclidCircularA-Bold"),
    url("./fonts/EuclidCircularA-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EuclidCircularA-Bold.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-Bold.woff") format("woff"),
    url("./fonts/EuclidCircularA-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Light.eot");
  src: local("Euclid Circular A Light"), local("EuclidCircularA-Light"),
    url("./fonts/EuclidCircularA-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EuclidCircularA-Light.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-Light.woff") format("woff"),
    url("./fonts/EuclidCircularA-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-BoldItalic.eot");
  src: local("Euclid Circular A Bold Italic"), local("EuclidCircularA-BoldItalic"),
    url("./fonts/EuclidCircularA-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EuclidCircularA-BoldItalic.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-BoldItalic.woff") format("woff"),
    url("./fonts/EuclidCircularA-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-LightItalic.eot");
  src: local("Euclid Circular A Light Italic"), local("EuclidCircularA-LightItalic"),
    url("./fonts/EuclidCircularA-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EuclidCircularA-LightItalic.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-LightItalic.woff") format("woff"),
    url("./fonts/EuclidCircularA-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Regular.eot");
  src: local("Euclid Circular A Regular"), local("EuclidCircularA-Regular"),
    url("./fonts/EuclidCircularA-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EuclidCircularA-Regular.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-Regular.woff") format("woff"),
    url("./fonts/EuclidCircularA-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-SemiBold.eot");
  src: local("Euclid Circular A SemiBold"), local("EuclidCircularA-SemiBold"),
    url("./fonts/EuclidCircularA-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EuclidCircularA-SemiBold.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-SemiBold.woff") format("woff"),
    url("./fonts/EuclidCircularA-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Medium.eot");
  src: local("Euclid Circular A Medium"), local("EuclidCircularA-Medium"),
    url("./fonts/EuclidCircularA-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EuclidCircularA-Medium.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-Medium.woff") format("woff"),
    url("./fonts/EuclidCircularA-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Italic.eot");
  src: local("Euclid Circular A Italic"), local("EuclidCircularA-Italic"),
    url("./fonts/EuclidCircularA-Italic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EuclidCircularA-Italic.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-Italic.woff") format("woff"),
    url("./fonts/EuclidCircularA-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-SemiBoldItalic.eot");
  src: local("Euclid Circular A SemiBold Italic"), local("EuclidCircularA-SemiBoldItalic"),
    url("./fonts/EuclidCircularA-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EuclidCircularA-SemiBoldItalic.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-SemiBoldItalic.woff") format("woff"),
    url("./fonts/EuclidCircularA-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-MediumItalic.eot");
  src: local("Euclid Circular A Medium Italic"), local("EuclidCircularA-MediumItalic"),
    url("./fonts/EuclidCircularA-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EuclidCircularA-MediumItalic.woff2") format("woff2"),
    url("./fonts/EuclidCircularA-MediumItalic.woff") format("woff"),
    url("./fonts/EuclidCircularA-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

* {
  font-family: Euclid Circular A, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

html {
  overflow-y: hidden;
}

body {
  font-family: inherit;
  margin: 0;
}

a:link,
a:visited {
  text-decoration: none;
  color: $gray-600;
}

h1 {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 22px !important;
  color: $gray-600;
}

h3 {
  font-size: 1.17em;
  font-weight: 700;
}

input {
  &:focus {
    outline: none;
  }
}

:focus {
  outline: rgba(96, 93, 255, 0.44) auto 2px;
  outline-offset: 1px !important;
  -moz-outline-radius: 6px;
}

:-webkit-direct-focus {
  outline-color: rgba(96, 93, 255, 0.44) auto 2px;
  outline-offset: 1px !important;
}

.add-device-input {
  .bp4-input {
    &::placeholder {
      color: rgb(216, 216, 216);
    }
  }
}

#search:focus {
  box-shadow: 0px 0px 0px 1px rgb(59, 130 ,246);
}

[id^="react-select"]:focus {
  box-shadow: none !important
}

// ::placeholder {
//   /* Chrome, Firefox, Opera, Safari 10.1+ */
//   color: red;
//   opacity: 1; /* Firefox */
// }

// :-ms-input-placeholder {
//   /* Internet Explorer 10-11 */
//   color: red;
// }

// ::-ms-input-placeholder {
//   /* Microsoft Edge */
//   color: red;
// }

a:hover {
  text-decoration: none !important
}