@import "../../_variables";

$gray-border: 1px $gray-300 solid;

table {
  background-color: white;
  border-collapse: collapse;
  width: 100%;

  th {
    border-bottom: $gray-border;
    background-color: #f9f9fb;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 12px;
    color: $gray-400;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: left; }

  tbody[role="rowgroup"] {
    font-weight: 500;
    font-size: 15px;
    color: $gray-400;

    tr {
      border-top: $gray-border;
      align-items: center;

      &:hover {
        background-color: $blue-50; }
      &:first-child {
        border-top-width: 0px; }
      &:last-child {
        border-bottom: $gray-border; } }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } } }

table#textbooks-table {
  tr {
    &.set {
      background-color: #f6fbff;
      td {
        padding: 10px 0;
        .selection-wrapper {
          padding-left: 15px; } } }
    &.book {
      font-size: 14px;
      &.selected {
        background-color: $indigo-50; }
      td {
        padding: 6px 0;
        .set-name {
          margin-left: 50px;
          color: #b9bbbe; }
        .selection-wrapper {
          padding-left: 25px; } } }
    th {
      // .selection-wrapper
 }      //   padding-left: 15px
    td, th {
      &:first-child {
        width: 55px; } } } }

// #textbook-checkout-table, #textbook-checkin-table
//   width: 100%
//   tbody
//     td
//       padding: 4px 5px

//   th
//     box-shadow: 0 -1px $gray-300 inset
//     border-bottom: 0

.textbooks-drawer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  &#checkout-table-container, &#add-table-container {
    height: calc(100% - 145px); }
  &#checkin-table-container {
    height: calc(100% - 90px); } }

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 -1px #d1d5db inset;
  border-bottom: 0; }

.checkout-table-footer {
  justify-content: flex-end;
  min-height: 50px;
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.15);
  margin-top: auto;
  background-color: white;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 25px; }

.table-toolbox-item {
  display: flex;
  align-items: center;
  margin-right: 45px;

  span {
    margin-right: 10px; } }

#add-textbook-table {
  tbody tr {
    &:first-child {
      td {
        padding-top: 8px; } }
    td {
      padding: 4px 0; } } }

.w-h-full {
  width: 100%;
  height: 100%; }

.flex-wrap {
  flex-wrap: wrap; }
.align-center {
  align-items: center; }
.align-start {
  align-items: start; }
.justify-space-between {
  justify-content: space-between; }
.space-evenly {
  justify-content: space-evenly; }
.space-between {
  justify-content: space-between; }
