.device-checkout-container {
  display: flex;
  flex-wrap: wrap; }

.device-checkout-box {
  width: 33.33%;
  padding: 15px;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  @media screen and (max-width: 1242px) and (min-width: 768px), (max-width: 684px) {
    width: 50%; }

  &.button {
    justify-content: flex-end;
    @media screen and (max-width: 1242px) and (min-width: 768px), (max-width: 684px) {
      width: 100%; }
    button {
      padding: 13px 20px;
      box-shadow: none;
      font-weight: 500;
      border-radius: 6px;
      background-color: #239eea; } } }
