@import "./_variables.sass";

$gray-border: 1px $gray-300 solid;

.custom-pop {
  box-shadow: 0px 0px 7px 1px #e1e1e1;
  border-radius: 9px !important;
}

.bp4-menu-item {
  border-radius: 5px !important;
}

.app-container {
  display: flex;
  overflow: hidden;
  // height: 100vh;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
}

.full {
  width: 100%;
  height: 100%;
}

.table-header {
  border-bottom: $gray-border;
  background-color: #f9f9fb;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  color: $gray-400;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: left;
}
