@import "../../../_variables";

.aftercare-student-table {
  th {
    padding: 0; }

  tr {
    & > :first-child {
      padding-left: 10px; } }

  .sub-td {
    color: $gray-500;
    font-size: 12px; }

  .checked {
    td {
      background-color: #fafafa; } } }
