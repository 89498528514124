$black: rgba(0, 0, 0, 1);
$white: rgba(255, 255, 255, 1);
$gray-50: rgba(249, 250, 251, 1);
$gray-100: rgba(243, 244, 246, 1);
$gray-200: rgba(229, 231, 235, 1);
$gray-300: rgba(209, 213, 219, 1);
$gray-400: rgba(156, 163, 175, 1);
$gray-500: rgba(107, 114, 128, 1);
$gray-600: rgba(75, 85, 99, 1);
$gray-700: rgba(55, 65, 81, 1);
$gray-800: rgba(31, 41, 55, 1);
$gray-900: rgba(17, 24, 39, 1);
$red-50: rgba(254, 242, 242, 1);
$red-100: rgb(254, 226, 226);
$red-200: rgba(254, 202, 202, 1);
$red-300: rgba(252, 165, 165, 1);
$red-400: rgba(248, 113, 113, 1);
$red-500: rgba(239, 68, 68, 1);
$red-600: rgba(220, 38, 38, 1);
$red-700: rgba(185, 28, 28, 1);
$red-800: rgba(153, 27, 27, 1);
$red-900: rgba(127, 29, 29, 1);
$yellow-50: rgba(255, 251, 235, 1);
$yellow-100: rgba(254, 243, 199, 1);
$yellow-200: rgba(253, 230, 138, 1);
$yellow-300: rgba(252, 211, 77, 1);
$yellow-400: rgba(251, 191, 36, 1);
$yellow-500: rgba(245, 158, 11, 1);
$yellow-600: rgba(217, 119, 6, 1);
$yellow-700: rgba(180, 83, 9, 1);
$yellow-800: rgba(146, 64, 14, 1);
$yellow-900: rgba(120, 53, 15, 1);
$emerald-50: rgba(236, 253, 245, 1);
$emerald-100: rgba(209, 250, 229, 1);
$emerald-200: rgba(167, 243, 208, 1);
$emerald-300: rgba(110, 231, 183, 1);
$emerald-400: rgba(52, 211, 153, 1);
$emerald-500: rgba(16, 185, 129, 1);
$emerald-600: rgba(5, 150, 105, 1);
$emerald-700: rgba(4, 120, 87, 1);
$emerald-800: rgba(6, 95, 70, 1);
$emerald-900: rgba(6, 78, 59, 1);
$blue-50: rgba(239, 246, 255, 1);
$blue-100: rgba(219, 234, 254, 1);
$blue-200: rgba(191, 219, 254, 1);
$blue-300: rgba(147, 197, 253, 1);
$blue-400: rgba(96, 165, 250, 1);
$blue-500: rgba(59, 130, 246, 1);
$blue-600: rgba(37, 99, 235, 1);
$blue-700: rgba(29, 78, 216, 1);
$blue-800: rgba(30, 64, 175, 1);
$blue-900: rgba(30, 58, 138, 1);
$indigo-50: rgba(238, 242, 255, 1);
$indigo-100: rgba(224, 231, 255, 1);
$indigo-200: rgba(199, 210, 254, 1);
$indigo-300: rgba(165, 180, 252, 1);
$indigo-400: rgba(129, 140, 248, 1);
$indigo-500: rgba(99, 102, 241, 1);
$indigo-600: rgba(79, 70, 229, 1);
$indigo-700: rgba(67, 56, 202, 1);
$indigo-800: rgba(55, 48, 163, 1);
$indigo-900: rgba(49, 46, 129, 1);
$purple-50: rgba(245, 243, 255, 1);
$purple-100: rgba(237, 233, 254, 1);
$purple-200: rgba(221, 214, 254, 1);
$purple-300: rgba(196, 181, 253, 1);
$purple-400: rgba(167, 139, 250, 1);
$purple-500: rgba(139, 92, 246, 1);
$purple-600: rgba(124, 58, 237, 1);
$purple-700: rgba(109, 40, 217, 1);
$purple-800: rgba(91, 33, 182, 1);
$purple-900: rgba(76, 29, 149, 1);
$pink-50: rgba(253, 242, 248, 1);
$pink-100: rgba(252, 231, 243, 1);
$pink-200: rgba(251, 207, 232, 1);
$pink-300: rgba(249, 168, 212, 1);
$pink-400: rgba(244, 114, 182, 1);
$pink-500: rgba(236, 72, 153, 1);
$pink-600: rgba(219, 39, 119, 1);
$pink-700: rgba(190, 24, 93, 1);
$pink-800: rgba(157, 23, 77, 1);
$pink-900: rgba(131, 24, 67, 1);
// EXTRAS
$lime-50: rgba(247, 254, 231, 1);
$lime-100: rgba(236, 252, 203, 1);
$lime-200: rgba(217, 249, 157, 1);
$lime-300: rgba(190, 242, 100, 1);
$lime-400: rgba(163, 230, 53, 1);
$lime-500: rgba(132, 204, 22, 1);
$lime-600: rgba(101, 163, 13, 1);
$lime-700: rgba(77, 124, 15, 1);
$lime-800: rgba(63, 98, 18, 1);
$lime-900: rgba(54, 83, 20, 1);
$teal-50: rgba(240, 253, 250, 1);
$teal-100: rgba(204, 251, 241, 1);
$teal-200: rgba(153, 246, 228, 1);
$teal-300: rgba(94, 234, 212, 1);
$teal-400: rgba(45, 212, 191, 1);
$teal-500: rgba(20, 184, 166, 1);
$teal-600: rgba(13, 148, 136, 1);
$teal-700: rgba(15, 118, 110, 1);
$teal-800: rgba(17, 94, 89, 1);
$teal-900: rgba(19, 78, 74, 1);
$sky-50: rgba(240, 249, 255, 1);
$sky-100: rgba(224, 242, 254, 1);
$sky-200: rgba(186, 230, 253, 1);
$sky-300: rgba(125, 211, 252, 1);
$sky-400: rgba(56, 189, 248, 1);
$sky-500: rgba(14, 165, 233, 1);
$sky-600: rgba(2, 132, 199, 1);
$sky-700: rgba(3, 105, 161, 1);
$sky-800: rgba(7, 89, 133, 1);
$sky-900: rgba(12, 74, 110, 1);
$fuchsia-50: rgba(253, 244, 255, 1);
$fuchsia-100: rgba(250, 232, 255, 1);
$fuchsia-200: rgba(245, 208, 254, 1);
$fuchsia-300: rgba(240, 171, 252, 1);
$fuchsia-400: rgba(232, 121, 249, 1);
$fuchsia-500: rgba(217, 70, 239, 1);
$fuchsia-600: rgba(192, 38, 211, 1);
$fuchsia-700: rgba(162, 28, 175, 1);
$fuchsia-800: rgba(134, 25, 143, 1);
$fuchsia-900: rgba(112, 26, 117, 1);
