.tabs {
  display: flex;
  flex-direction: row;

  & > * {
    margin-right: 10px; }

  .tab {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    color: #6B7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    border-radius: 0.375rem;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: #F3F4F6;
      color: #374151; }

    &.current {
      color: #4338CA;
      background-color: #E0E7FF; } } }
