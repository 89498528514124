.entry-input {
  border: 1px #ccc solid;

  &:focus {
    border: 1px #2684FF solid;
    box-shadow: 0px 0px 0px 1px #2684FF; } }

.date-selector {
  .bp4-label {
    width: 100%; } }
