.primary-button {
  background-color: #2972e7;
  color: white;
  border: none;
  padding: 0.625rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  // width: 100%
  &:hover {
    background-color: #1a64db; }
  &:focus {
    --tw-ring-offset-color: #fefefe;
    --tw-ring-offset-width: 2px;
    --tw-shadow: 0 0 #0000;
    --tw-ring-color: rgba(26, 100, 219, var(--tw-ring-opacity));
    --tw-ring-opacity: 1;

    outline: none;
    --tw-ring-offset-shadow: 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 #0000); }
  &:active {
    background-color: #1253bb; }

  &.primary-button-fill {
    width: 100%; }

  &[disabled] {
    background-color: #c4c4c4; } }
