@import "../../variables";

.topbar {
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px $gray-200 solid;
  position: sticky;
  top: 0;
  justify-content: space-between;

  .hamburger-button {
    padding-left: 1rem;
    padding-right: 1rem;
    outline: none;
    border: none;
    background-color: transparent;
    border-right: 1px $gray-200 solid;
    cursor: pointer; }

  .profile-image-wrapper {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .topbar-profile-button {
      display: block;
      border: none;
      cursor: pointer;
      width: 35px;
      height: 35px;
      padding: 0;
      img {
        border-radius: 9999px;
        flex-shrink: 0;
        max-width: 100%; } } } }

.bp4-overlay {
  z-index: 100 !important; }
