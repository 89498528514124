.session-stats-container {
  display: grid;
  grid-gap: 35px;
  grid-template-columns: 2fr 2fr auto;
  margin-bottom: 25px;

  @media screen and ( max-width: 640px ) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 15px; } }

.session-header {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px; }
