.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%; }

.blinking {
  // background-color: white
  animation-name: blinking;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

@keyframes blinking {
  50% {
    background-color: transparent; } }
