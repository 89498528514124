.short-link-table {
  tr {
    td, th {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:first-child {
        padding-left: 15px;
        padding-right: 15px; } }
    td {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: 15px; } } }

.short-link-table-mobile {
  background-color: white;
  .header {
    text-transform: uppercase;
    font-size: 12px;
    color: #9ca3af;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: left; } }
