.device-checkout-history-container {
  padding: 15px; }

.device-checkout-history-wrapper {
  box-shadow: #d4d4d4 0px 0px 2px 1px;
  border-radius: 8px;
  &.none {
    box-shadow: none; } }

.device-checkout-history-row {
  padding: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid #d4d4d4; }

  .dchr-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .student-name {
      color: #4f46e5;
      font-weight: 500; } }

  .dchr-bottom {
    display: flex;
    justify-content: space-between;
    color: #bcc0d6; } }
