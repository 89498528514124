@import "../../_variables";

.main-content {
  flex-grow: 1;
  overflow: scroll;
  background-color: white; }

.main-content-inner-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.main-content-header {
  padding: 0.75rem 1.5rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: $white;
  border-bottom: 1px $gray-200 solid;
  @media screen and (max-width: 379px) {
    flex-wrap: wrap; }

  .button-wrapper {
    @media screen and (max-width: 379px) {
      margin-top: 10px; } }

  .content-header-text {
    display: flex;
    align-items: center;
    width: 68%;
    @media screen and (max-width: 379px) {
      width: 100%; } } }

.main-content-footer {
  margin-top: auto;
  padding: 0.75rem 1.5rem;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  border-top: 1px #e5e7eb solid; }

.custom-panel-stack {
  height: 100%; }

.add-input {
  padding: 2px 0; }

.create-textbook-button {
  border: none;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0px 0px 2.5px 0px #999999;
  border-radius: 9px;
  cursor: pointer;
  margin-top: 10px;
  color: #797979;
  &:hover {
    background-color: #f2faff; }
  &:active {
    background-color: #d8eefc;
    box-shadow: 0px 0px 4px 0px #999999; }
  &:disabled {
    background-color: $gray-200;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.5; }
  &.button-fill {
    width: 100%; } }

.lock-button-wrapper {
  align-self: center;
  width: 50%;
  height: 100%;
  margin-top: 5px;
  padding: 0 15px; }

.create-textbook-data {
  padding: 20px;
  border-bottom: 1px $gray-200 solid;
  @media screen and (max-width: 992px) {
    padding: 10px 4px; } }

.empty-state-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.empty-state-border {
  padding: 4rem 6rem;
  border: 2px $gray-200 dashed;
  border-radius: 16px; }
