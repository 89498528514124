.device-checkin-container {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  @media screen and ( max-width: 650px ) {
    display: block; } }

.device-checkin-radio-container {
  margin-bottom: 20px; }

.device-checkin-error-form {
  width: 75%; }

.device-checkin-left {
  width: 70%;
  padding-right: 8px;
  @media screen and ( max-width: 650px ) {
    width: 100%; } }

.device-checkin-right {
  .device-checkin-button {
    padding: 13px 20px;
    box-shadow: none;
    font-weight: 500;
    border-radius: 6px;
    background-color: #239eea; } }
