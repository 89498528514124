@import "../../_variables";

.badge {
  display: inline-flex;
  align-items: center;
  padding-top: .125rem;
  padding-bottom: .125rem;
  padding-left: .625rem;
  padding-right: .625rem;
  border-radius: 9999px;
  font-weight: 500;
  font-size: .75rem;
  line-height: 1rem;

  svg {
    margin-right: .375rem;
    margin-left: -.125rem;
    width: .5rem;
    height: .5rem; } }
