.device-grid-container {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  @media screen and ( max-width: 700px ) {
    grid-template-columns: 1fr; }

  .device-item {
    display: flex;
    width: 100%;
    box-shadow: 0 1px 6px #c3c3c3;
    padding: 20px;
    border-radius: 8px;
    text-decoration: none;
    transition: 400ms;
    background-color: white;
    &:hover {
      color: inherit;
      box-shadow: 0 1px 9px 3px #c3c3c3; }
    img {
      width: 40px;
      height: 40px; }
    > div {
      margin-left: 20px; }
    .device-heading {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 5px; } } }

.device-wrapper {
  margin-top: 20px; }
