@import "../../../_variables";

table#textbooks-table {
  table-layout: fixed;
  th,td {
    &:first-child {
      padding-left: 15px;
      padding-top: 2px;
      padding-bottom: 2px; }
    &:last-child {
      width: 60px;
      padding-top: 2px;
      padding-bottom: 2px; }
    &:nth-child(2) {
      width: 50px; }
    &:nth-child(3) {
      width: 120px; }
    &:nth-child(4) {
      @media screen and ( max-width: 1094px ) and (min-width: 992px),( max-width: 837px ) and (min-width: 768px) {
        width: 120px; } }
    &:nth-child(5) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } }

  th {
    padding: 10px 7px; }
  tbody {
    font-size: 13px; }

  td {
    padding: 5px 7px;
    text-overflow: clip; }

  .selection-wrapper {
    // padding-left: 15px
    display: flex; }

  .options-cell {
    max-width: 20px; } }

.textbook-mobile-row {
  display: flex;
  padding: 10px;
  border-bottom: 1px $gray-200 solid;

  .textbook-mobile-row-data {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    color: $gray-500; }

  .textbook-mobile-row-title {
    font-weight: 500;
    margin-bottom: 7px; }

  .textbook-mobile-row-badges {
    margin-bottom: 5px;

    .badge {
      margin-right: 5px; } } }

.textbook-mobile-row-header {
  padding: 2px 10px;
  background-color: $gray-100;
  top: 0;
  position: sticky;
  border-bottom: 1px $gray-200 solid;
  z-index: 10; }
