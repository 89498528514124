@import "../../_variables";

.side-table {
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  background-color: white;
  border-right: 1px #e5e7eb solid;
  height: 100%;
  @media screen and (max-width: 1032px) and (min-width: 992px) {
    min-width: 18rem; }
  @media screen and (max-width: 767px) {
    min-width: 100%; } }

.side-table-top {
  padding: 1.5rem 1.5rem 1rem;
  border-bottom: 1px $gray-200 solid; }

.side-table-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto; }

.side-table-header {
  background-color: $gray-50;
  padding: 0.25rem 1.5rem;
  position: sticky;
  top: 0;
  border-bottom: 1px $gray-200 solid;
  color: $gray-400; }

.side-table-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  .side-table-row {
    border-bottom: 1px $gray-200 solid;
    cursor: pointer;
    &.highlight-row {
      background-color: #eff6ff;
      &:hover {
        background-color: #eff6ff; } }
    &:hover {
      background-color: $gray-50; } } }

.side-table-filter {
  flex-grow: 1; }

.side-table-filter > .bp4-input {
  border-radius: 8px;
  box-shadow: 0px 0px 2px 1px #c6c6c6;
  &:focus {
    box-shadow: 0 0 0px 2px #3b82f6; } }
