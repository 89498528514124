@import "../../variables";

@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.profile-button {
  display: block;
  padding: 8px 14px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;

  &:hover {
    background-color: $gray-200; }

  &:focus {
    --tw-ring-offset-color: #fefefe;
    --tw-ring-offset-width: 2px;
    --tw-shadow: 0 0 #0000;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
    --tw-ring-opacity: 1;

    outline: none;
    --tw-ring-offset-shadow: 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 #0000); }

  .profile-button-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }

  .profile-button-content {
    display: flex;
    min-width: 0;
    align-items: center;
    justify-content: space-between;

    img {
      border-radius: 9999px;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      max-width: 100%; }

    span {
      flex-direction: column;
      flex: 1 1 0%;
      min-width: 0; }

    .profile-button-text {
      margin-left: 12px;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      @include truncate;

      .profile-name {
        @include truncate;
        font-weight: 500;
        color: $gray-900; }

      span {
        @include truncate;
        color: $gray-500;
        font-size: 12.5px; } } } }
