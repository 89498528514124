@import "../../../_variables";

.session-stat {
  background-color: white;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  width: 95%;
  padding: 20px;
  border-radius: 6px;

  &.disable {
    background-color: #ebebeb; } }

.session-stat_header {
  font-size: 15px;
  // font-weight: 500
  color: $gray-500;
  margin-bottom: 5px; }

.session-stat_value {
  font-weight: 600;
  color: $gray-900;
  font-size: 30px; }
